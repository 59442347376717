import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "MixCast PhotoBooth",
  "featuredImage": "images/mixcast-2528iPad-2529-2B-25282-2529.png",
  "coverAlt": "MixCast PhotoBooth",
  "description": "UI, UX, Front end development of the MixCast PhotoBooth app at BluePrint Reality",
  "date": "2018-11-15",
  "dateModified": "2018-11-15",
  "category": "portfolio",
  "pinned": false,
  "featured": false,
  "tags": ["Front End Development", "UI", "UX"]
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`One of the most fun projects I’ve worked on - I was given full creative freedom to bring the product to life. `}<strong parentName="p">{`MixCast VR PhotoBooth`}</strong>{` looks great on any device in landscape and portrait modes regardless. `}<em parentName="p">{`Scroll down for more screenshots and a video showing the animations.`}</em></p>
    <h2 {...{
      "id": "skills-used",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#skills-used",
        "aria-label": "skills used permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Skills Used`}</h2>
    <p>{`HTML, SASS/SCSS, React, UI/UX development, responsive design`}<br parentName="p"></br>{`
`}{`Mobile-first design, CSS3 Animations, etc.`}</p>
    <h2 {...{
      "id": "tablet-screenshots",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#tablet-screenshots",
        "aria-label": "tablet screenshots permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Tablet Screenshots`}</h2>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}></th>
          <th parentName="tr" {...{
            "align": null
          }}></th>
          <th parentName="tr" {...{
            "align": null
          }}></th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><img parentName="td" {...{
              "src": "images/localhost_%2528iPad%2529%2B%25281%2529.png",
              "alt": "MixCast PhotoBooth"
            }}></img></td>
          <td parentName="tr" {...{
            "align": null
          }}><img parentName="td" {...{
              "src": "images/localhost_%2528iPad%2529.png",
              "alt": "MixCast PhotoBooth"
            }}></img></td>
          <td parentName="tr" {...{
            "align": null
          }}><img parentName="td" {...{
              "src": "images/localhost_%2528iPad%2529%2B%25282%2529.png",
              "alt": "MixCast PhotoBooth"
            }}></img></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><img parentName="td" {...{
              "src": "images/localhost_%2528iPad%2529%2B%25283%2529.png",
              "alt": "MixCast PhotoBooth"
            }}></img></td>
          <td parentName="tr" {...{
            "align": null
          }}><img parentName="td" {...{
              "src": "images/localhost_%2528iPad%2529%2B%25284%2529.png",
              "alt": "MixCast PhotoBooth"
            }}></img></td>
          <td parentName="tr" {...{
            "align": null
          }}><img parentName="td" {...{
              "src": "images/localhost_%2528iPad%2529%2B%25285%2529.png",
              "alt": "MixCast PhotoBooth"
            }}></img></td>
        </tr>
      </tbody>
    </table>
    <h2 {...{
      "id": "",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#",
        "aria-label": " permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a></h2>
    <h2 {...{
      "id": "mobile-device-phone-screenshots",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#mobile-device-phone-screenshots",
        "aria-label": "mobile device phone screenshots permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Mobile Device (Phone) Screenshots`}</h2>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}></th>
          <th parentName="tr" {...{
            "align": null
          }}></th>
          <th parentName="tr" {...{
            "align": null
          }}></th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><img parentName="td" {...{
              "src": "images/localhost_%2528Galaxy%2BS5%2529.png",
              "alt": "MixCast PhotoBooth"
            }}></img></td>
          <td parentName="tr" {...{
            "align": null
          }}><img parentName="td" {...{
              "src": "images/localhost_%2528Galaxy%2BS5%2529%2B%25281%2529.png",
              "alt": "MixCast PhotoBooth"
            }}></img></td>
          <td parentName="tr" {...{
            "align": null
          }}><img parentName="td" {...{
              "src": "images/localhost_%2528Galaxy%2BS5%2529%2B%25284%2529.png",
              "alt": "MixCast PhotoBooth"
            }}></img></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><img parentName="td" {...{
              "src": "images/localhost_%2528Galaxy%2BS5%2529%2B%25282%2529.png",
              "alt": "MixCast PhotoBooth"
            }}></img></td>
          <td parentName="tr" {...{
            "align": null
          }}><img parentName="td" {...{
              "src": "images/localhost_%2528Galaxy%2BS5%2529%2B%25283%2529.png",
              "alt": "MixCast PhotoBooth"
            }}></img></td>
          <td parentName="tr" {...{
            "align": null
          }}><img parentName="td" {...{
              "src": "images/localhost_%2528Galaxy%2BS5%2529%2B%25285%2529.png",
              "alt": "MixCast PhotoBooth"
            }}></img></td>
        </tr>
      </tbody>
    </table>
    <p><strong parentName="p">{`UI/UX - Animations + Quick Demo`}</strong></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      